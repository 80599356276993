<template>
  <section>
    <PrismicRichText :field="slice.primary.text" class="heading-text" />
  </section>
</template>

<script>
import { getSliceComponentProps } from "@prismicio/vue/components";

export default {
  // The array passed to `getSliceComponentProps` is purely optional.
  // Consider it as a visual hint for you when templating your slice.
  props: getSliceComponentProps(["slice", "index", "slices", "context"]),
};
</script>
<style lang="scss">
.heading-text {
  max-width: calc($large - 15rem);
  margin: 1rem auto;
}
.heading-text > * {
  font-family: $font-primary !important;
}
</style>
